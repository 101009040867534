<template>
  <v-container fluid>
    <v-row class="login-container text-center full-height">
      <v-col class="login-container-card" cols="12">
        <v-col class="login-card">
          <div class="branding-container">
            <div class="branding">
              <img class="login-logo" src="@/assets/images/ph-logo-horizontal.svg" />
              <p class="login-system-title">Unified Service Portal</p>
            </div>
          </div>
          <br>
          <div class="ph-field-container">
            <div class="p-col-12 p-d-flex p-flex-column p-ai-center p-jc-center">
                <div class="login-page-icon-container">
                    <img class="login-page-icon" src="@/assets/images/icons/lock-icon.svg" />
                </div>
                <h1 class="reset-password-title">Two-factor Authentication</h1>
                <p class="reset-password-subtitle">Input your 6-digit One-time password PIN from your authenticator app.</p>
                <v-col fluid cols="12" class="ph-textbox-container ph-otp-input-container">
                  <v-text-field
                    v-for="index in 6"
                    v-model="otp_code[index - 1]"
                    :error="(!hasOTPCode && hasProcessOTP) ? true : false"
                    :key="index"
                    :ref="'otpTxt' + index"
                    @paste="getCode($event,index)"
                    @keyup="tabSwitch(index)"
                    @keyup.enter="verifyOTP"
                    type="number"
                    placeholder="-"
                    hide-details
                    outlined
                    maxlength="1"
                    hide-spin-buttons
                    class="ph-textbox ph-otp-textbox">
                  </v-text-field>
                </v-col>
                <v-col fluid cols="12" class="ph-action-buttons">
                  <v-col fluid cols="12" class="ph-action-button">
                    <v-btn ref="verifyBtn" @click="verifyOTP" x-large color="#1A2791" elevation="0" class="ph-login-button ph-login-reset-btn">
                      Verify OTP
                    </v-btn>
                  </v-col>
                  <v-col fluid cols="12" class="ph-back-button-container">
                    <router-link @click.native="backToLoginPage" to="/verify-otp-code" class="ph-back-button">
                      <img class="ph-back-logo" src="@/assets/images/icons/caret-back.svg" />
                      <p class="ph-back-text">Back to Login</p>
                    </router-link>
                  </v-col>
                </v-col>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { renderToast, aesEncrypt } from '@/utils'

export default {
  name: 'VerifyOneTimeCode',
  data () {
    return {
      otp_code: ['', '', '', '', '', ''],
      hasOTPCode: '',
      hasProcessOTP: false,
      getYear: format(new Date(), 'yyyy')
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'currUser',
      isAuthenticated: 'isAuthenticated',
      isOTPpassed: 'isOTPpassed',
      authTimestamp: 'authTimestamp'
    })
  },
  beforeCreate () {
    if (this.currUser === null) {
      this.$router.push('/login')
    }
  },
  created () {
    if (this.isOTPpassed) {
      location.href = this.$uspMainAppURL
    } else if (!this.isAuthenticated) {
      this.$router.push('/login')
    } else {
      this.$store.commit('SET_OTP_PASSED', false)
    }
  },
  mounted () {
    if (localStorage.getItem('otp_required_message')) {
      renderToast('warn', 'OTP not verified', localStorage.getItem('otp_required_message'))
    }

    this.$store.commit('SET_LOADING', false)

    setTimeout(function () {
      localStorage.removeItem('otp_required_message')
    }, 3500)
  },
  methods: {
    backToLoginPage () {
      const that = this
      this.$store.commit('SET_CURRENT_USER', null)
      this.$store.commit('SET_AUTHENTICATED', false)

      setTimeout(function () {
        that.$router.push('/login')
        localStorage.setItem('logout_message', 'You are now logged-out.')
      }, 1000)
    },
    validateInput (e) {
      e.data = e.data.replace(/[^0-9]/g, '')
    },
    tabSwitch (val) {
      const element = document.querySelectorAll('input')

      if (element[val - 1].value !== '' && val !== 6) {
        element[val].focus()
      } else if (element[val - 1].value === '' && val !== 1) {
        element[val - 2].focus()
      }
    },
    getCode (e, index) {
      let clipboardValue = ''
      let pastedValue = ''

      e.stopPropagation()
      e.preventDefault()

      // Get pasted data via clipboard API
      clipboardValue = e.clipboardData || window.clipboardData
      pastedValue = clipboardValue.getData('Text')

      const pastedValueToArray = pastedValue.split('')

      this.otp_code = pastedValueToArray

      this.$refs.verifyBtn.$el.focus()
    },
    verifyOTP () {
      const that = this
      const otpCodeArray = this.otp_code
      const otpCodeString = otpCodeArray.join('')

      this.hasProcessOTP = true

      const payload = {
        user_name: this.currUser.user_name,
        otp_code: otpCodeString,
        pin: this.currUser.otp_pin,
        module: 'login'
      }

      if (!this.otp_code || (this.otp_code.length < 6)) {
        renderToast('error', 'Invalid Code', 'The OTP Code is Invalid. The code must be 6-digits.')
      } else {
        this.$store.commit('SET_LOADING', true)

        // ajax call
        this.$store.dispatch('verifyOTP', payload).then(res => {
          const response = res.result

          this.$store.commit('SET_LOADING', false)

          if (res?.result && response !== false) {
            const currentUserData = { ...res.result, ...this.currUser }

            this.$store.commit('SET_CURRENT_USER', currentUserData)

            this.$store.commit('SET_OTP_PASSED', true)
            renderToast('success', 'Verification Success', 'Authentication successful! Redirecting you to our dashboard.')
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.dispatch('retrieveUserSignature', { user_id: this.currUser.user_id }).then(res => {
              if (res.result.length !== 0) {
                that.$store.state.currUser.userSignature = res.result[0].bucket_path
              }
            })

            const userData = { ...currentUserData }
            // const userModules = userData.modules
            const userToken = userData.token
            const userAuthTimeStamp = this.authTimestamp

            delete userData.modules
            delete userData.token

            this.$cookies.set('USP_DATA', aesEncrypt(JSON.stringify(userData)))
            this.$cookies.set('USP_TOKEN', aesEncrypt(JSON.stringify(userToken)))
            // this.$cookies.set('USP_MODULES', aesEncrypt(JSON.stringify(userModules)))
            this.$cookies.set('USP_AUTH_TIMESTAMP', aesEncrypt(JSON.stringify(userAuthTimeStamp)))

            location.href = this.$uspMainAppURL
          } else {
            this.otp_code = ['', '', '', '', '', '']
            renderToast('error', 'Invalid OTP Code', 'The OTP Code is invalid or already expired. Please try again.')
            this.$refs.otpTxt1?.$el?.focus()
          }
        }).catch((err) => {
          this.$store.commit('SET_LOADING', false)
          this.otp_code = ['', '', '', '', '', '']
          renderToast('error', 'An error has occured', err)
          this.$refs.otpTxt1?.$el?.focus()
        })

        // this.$router.push('/');
      }
    }
  }
}
</script>
<style scoped>
  .login-logo {
    margin: auto;
    margin-bottom: 0;
    height: 25px;
    width: auto;
  }

  .login-inputs {
    margin-bottom: 25px;
  }

  .login-container-card {
    text-align: center;
  }

  .login-system-title {
    text-align: right;
    font-family: "Proxima Nova";
    font-size: 11.6px;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    bottom: 5px;
  }

  .branding {
    margin: auto;
    margin-top: 30px;
    height: 50px;
  }

  .branding-container {
    display: flex;
    margin-top: 0;
  }

  .reset-password-title {
    font-family: 'Baloo Extra Bold';
    font-size: 24px;
    font-weight: bolder;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #1A2791;
  }

  .reset-password-subtitle {
    font-size: 14px;
  }

  .ph-login-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    width: 100%;
    transition: .2s;
    height: 60px !important;
  }

  .ph-login-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-login-button.ph-login-reset-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
    z-index: 1;
  }

  .login-container-card .ph-back-button {
    color: #1A2791;
    display: flex;
    margin-top: 30px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .ph-back-logo {
    margin-right: auto;
  }

  .ph-back-text {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Proxima Nova Bold" !important;
    text-decoration: none;
    font-size: 14px;
  }

  .ph-action-button {
    padding-top: 20px;
  }

  .ph-action-button,
  .ph-back-button {
    padding-left: 0;
    padding-right: 0;
  }

  .ph-mail-disclaimer {
    margin-top: 70px;
    font-size: 14px;
  }

  .footer {
    position: relative;
    top: 15px;
    font-size: 12px;
    text-align: center;
    margin: auto;
  }

  .ph-textbox-container {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .login-card {
    margin: auto;
  }

  .ph-otp-input-container {
    display: flex;
    flex-direction: row;
  }

  .ph-otp-textbox {
    margin: 0 4px !important;
  }

  @media only screen and (min-width: 960px) {
    .branding-container {
      height: 100%;
      margin-top: 50px;
    }

    .login-logo {
      height: 30px;
    }

    .login-system-title {
      font-size: 14px;
      position: relative;
      bottom: 5px;
    }

    .branding {
      margin-top: auto;
    }

    .footer {
      position: relative;
      top: 100px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 500px) {
    .login-card {
      margin: auto;
      text-align: center;
      width: 395px;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
    }

    .ph-otp-textbox {
      margin: 0 9px !important;
    }
  }
</style>
